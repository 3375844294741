<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      v-bind="configOptions"
      @handleNewClick="handleSubmit"
      :pageInfo="pageInfo"
    >
    </page-form>
  </div>
</template>

<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import {
  baseNewFormConfig,
  baseUpdateFormConfig,
  baseViewFormConfig
} from './config/index'
import { findCurrentSelectedTreeNode } from '@/utils/util'

import { ref, getCurrentInstance, onMounted, computed, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import i18n from '@/i18n'
import { cloneDeep } from 'lodash'
import { getParamsOptions } from '@/utils/common'

const routes = useRoute()
const router = useRouter()
const { t } = i18n.global

const { appContext } = getCurrentInstance()
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

// 1、预定义当前页面的map映射数组
const pageMapArray = [
  {
    // view routesName 跟你配置路由的name相关
    routesName: 'CustomerView',
    baseFormConfig: baseViewFormConfig,
    pageTitle: t('customer.customer-view'),
    pageType: 'view'
  },
  {
    routesName: 'CustomerEdit',
    baseFormConfig: baseUpdateFormConfig,
    pageTitle: t('customer.customer-edit'),
    pageType: 'edit',
    // edit page特有的属性 reset时不将表单置为空
    isResetFields: false
  },
  {
    routesName: 'CustomerNew',
    baseFormConfig: baseNewFormConfig,
    pageTitle: t('customer.customer-new'),
    pageType: 'add'
  }
]

// 2、根据当前路由对象中的name属性判断当前页面
const currentPageConfig = pageMapArray.find((item) => {
  if (item.routesName === routes.name) return item
})

const store = useStore()
// 3、将需要用到的属性解构出来
const { baseFormConfig, pageType } = currentPageConfig

const baseFormConfigRef = computed(() => {
  return baseFormConfig
})

// 4、区分当前页面是否需要转化options选项
currentPageConfig.baseFormConfig = baseFormConfigRef
const configOptions = ref({})
configOptions.value = currentPageConfig

const id = routes.params.id

const { proxy } = getCurrentInstance()
const pageInfo = ref({})

const tenantList = getParamsOptions('tenantList')
// 5、定义方法获取当前页面的初始化数据
const getCurrentPageInfo = async () => {
  const customerInfo = await store.dispatch('tenant/handleGetCustomer', {
    id
  })

  if (JSON.stringify(customerInfo) !== '{}') {
    // 單選
    customerInfo.tenantId =
      findCurrentSelectedTreeNode(tenantList, customerInfo.tenantId, 'id') || ''
    // 多選
    // customerInfo.tenantId = customerInfo.tenantId?.split(',') || []
    customerInfo.createdTime = proxy.$filters.fmtDateTime(
      customerInfo.createdTime
    )
    pageInfo.value = customerInfo
  }
}

// 6、判断当前页是否需要调用getCurrentPageInfo
if (['view', 'edit'].includes(pageType)) {
  getCurrentPageInfo()
} else {
  pageInfo.value = {}
}

// 修复tenantId在初始点击进来页面就爆红问题
const baseFormRef = ref('')
onMounted(() => {
  nextTick(() => {
    baseFormRef.value.handleRemoveFieldValidate('tenantId')
  })
})

// 7、定义被提交的方法
const handleSubmit = async (data) => {
  const form = cloneDeep(data)
  let url = 'handleCustomerAction'
  form.tenantId = form.tenantId
    ? findCurrentSelectedTreeNode(tenantList, form.tenantId, 'name')
    : ''
  if (pageType === 'edit') {
    form.id = id
    url = 'handleUpdateCustomer'
  }
  handleMessageCommit(`tenant/${url}`, form).then(() => {
    router.push('/tenant/customer')
  })
}
</script>

<style scoped></style>
