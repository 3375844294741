import i18n from '@/i18n'

const { t } = i18n.global

const Validator = {
  float: async (rule, value, callback) => {
    const regex = /^([-+])?(\d+(\.\d+)?|Infinity)$/
    if (value && !regex.test(value)) {
      callback(rule.message || t('template.float'))
    } else {
      callback()
    }
  },

  integer: async (rule, value, callback) => {
    const regex = /^([-+])?(\d+|Infinity)$/
    if (value && !regex.test(value)) {
      callback(rule.message || t('template.integer'))
    } else {
      callback()
    }
  },

  lessThan: async (rule, value, callback) => {
    const number = parseFloat(value)
    if (!isNaN(number) && number >= rule.bound) {
      callback(rule.message || t('template.lessThan', { num: rule.bound }))
    } else {
      callback()
    }
  },

  lessThanOrEqualTo: async (rule, value, callback) => {
    const number = parseFloat(value)
    if (!isNaN(number) && number > rule.bound) {
      callback(
        rule.message || t('template.lessThanOrEqualTo', { num: rule.bound })
      )
    } else {
      callback()
    }
  },

  greaterThan: async (rule, value, callback) => {
    const number = parseFloat(value)
    if (!isNaN(number) && number <= rule.bound) {
      callback(rule.message || t('template.greaterThan', { num: rule.bound }))
    } else {
      callback()
    }
  },

  greaterThanOrEqualTo: async (rule, value, callback) => {
    const number = parseFloat(value)
    if (!isNaN(number) && number < rule.bound) {
      callback(
        rule.message || t('template.greaterThanOrEqualTo', { num: rule.bound })
      )
    } else {
      callback()
    }
  },

  between: async (rule, value, callback) => {
    const number = parseFloat(value)
    if (isNaN(number) || number < rule.lowBound || number > rule.highBound) {
      callback(
        rule.message ||
          t('template.between', { min: rule.lowBound, max: rule.highBound })
      )
    } else {
      callback()
    }
  },

  size: async (rule, value, callback) => {
    if (!Array.isArray(value) || value.length !== rule.size) {
      callback(rule.message || t('template.size', { num: rule.size }))
    } else {
      callback()
    }
  },

  length: async (rule, value, callback) => {
    if (typeof value !== 'string' || value.length !== rule.length) {
      callback(rule.message || t('template.length', { num: rule.length }))
    } else {
      callback()
    }
  },

  minLength: async (rule, value, callback) => {
    if (value && String(value).length < rule.length) {
      callback(rule.message || t('template.minLength', { num: rule.length }))
    } else {
      callback()
    }
  },

  maxLength: async (rule, value, callback) => {
    if (value && String(value).length > rule.length) {
      callback(rule.message || t('template.maxLength', { num: rule.length }))
    } else {
      callback()
    }
  },

  lengthBetween: async (rule, value, callback) => {
    const stringLength = String(value).length
    if (
      value &&
      (stringLength < rule.minLength || stringLength > rule.maxLength)
    ) {
      callback(
        rule.message ||
          t('template.maxLength', { min: rule.minLength, max: rule.maxLength })
      )
    } else {
      callback()
    }
  },

  inOptions: async (rule, value, callback) => {
    if (value && !rule.options.includes(value)) {
      callback(rule.message || t('template.in', { value: rule.options }))
    } else {
      callback()
    }
  },

  notInOptions: async (rule, value, callback) => {
    if (value && rule.options.includes(value)) {
      callback(rule.message || t('template.notIn', { value: rule.options }))
    } else {
      callback()
    }
  },

  matchValue: async (rule, value, callback) => {
    if (value && value !== rule.valueToCompare) {
      callback(rule.message || t('template.match'))
    } else {
      callback()
    }
  },

  regex: async (rule, value, callback) => {
    const regex =
      rule.regex instanceof RegExp ? rule.regex : new RegExp(rule.regex)
    if (value && !regex.test(value)) {
      callback(rule.message || t('template.regex'))
    } else {
      callback()
    }
  },

  digit: async (rule, value, callback) => {
    if (value && !/^\d*$/.test(value)) {
      callback(rule.message || t('template.digit'))
    } else {
      callback()
    }
  },

  email: async (rule, value, callback) => {
    const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/
    if (value && !emailRegex.test(value)) {
      callback(rule.message || t('template.email'))
    } else {
      callback()
    }
  },

  url: async (rule, value, callback) => {
    const urlRegex =
      /(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:~+#-]*[\w@?^=%&~+#-])?/
    if (value && !urlRegex.test(value)) {
      callback(rule.message || t('template.url'))
    } else {
      callback()
    }
  },

  amount: async (rule, value, callback) => {
    if (value || value === 0) {
      const reg = /^[1-9]\d*(\.\d{1,10})?$/
      if (!reg.test(value)) {
        callback(rule.message || t('template.amount'))
      } else {
        callback()
      }
    } else {
      callback()
    }
  },
  time: async (rule, value, callback) => {
    if (value) {
      var reg = /^([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/
      if (!reg.test(value)) {
        callback(new Error('Please enter a valid time(00:00:00)!'))
      } else {
        callback()
      }
    } else {
      callback()
    }
  }

  // Add other validators...
}

export default Validator
